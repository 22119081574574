<template>
  <div>
    <template v-if="isLoading">
      <OtherLoading />
    </template>
    <template v-else>
      <template v-if="!notFound">
        <div class="row flex-nowrap wrapper">
          <div class="col">
            <div class="mb-2">
              <div class="content-between">
                <h2 class="header-tablepage">
                  {{ form.first_name_th }} {{ form.last_name_th }} ({{
                    form.member_id
                  }})
                </h2>
                <div v-if="form.userRefDchat.length > 0">
                  <b-button
                    @click.prevent="openChat(form.userRefDchat[0].urlRef)"
                    class="btn-filter mr-2"
                    v-if="form.userRefDchat.length == 1"
                  >
                    <font-awesome-icon icon="comment" class="d-md-none" />
                    <span class="d-none d-md-block">
                      <font-awesome-icon icon="comment" class="pointer" />
                      <span class="btn-text">Chat</span>
                    </span>
                  </b-button>
                  <b-dropdown
                    id="dropdown"
                    right
                    class="btn-mobile mr-2"
                    v-else
                  >
                    <template #button-content>
                      <font-awesome-icon icon="comment" class="pointer" />
                      <span class="btn-text mr-2">Chat</span>
                    </template>
                    <slot name="content-dropdown">
                      <template v-for="link of form.userRefDchat">
                        <b-dropdown-item
                          @click="openChat(link.urlRef)"
                          :key="link.urlRef"
                        >
                          <span>{{ link.socialName }}</span>
                        </b-dropdown-item>
                      </template>
                    </slot>
                  </b-dropdown>
                </div>
              </div>
            </div>

            <div class="bg-white mt-3">
              <TabHistoryDetail
                ref="tab"
                :form="form"
                @setForm="setForm"
                :memberLevelList="memberLevelList"
                :FieldList="FieldList"
                @updateAddress="updateProfileDetail"
                :v="$v"
                @openModalPoint="openModalPoint"
                :customerQuestionList="customerQuestionList"
                @updatePersonal="updatePersonal"
                :isLoadingPersonal="isLoadingPersonal"
                :isLoadingUpdatePersonal="isLoadingUpdatePersonal"
                :isLoadingUpdateProfile="isLoadingUpdateProfile"
                :isConsentList="isConsentList"
                :fieldsPurchase="fieldsPurchase"
                :fieldCoupon="fieldCoupon"
                :itemsCoupon="couponList"
                :itemsPurchase="itemsPurchase"
                :rowsPurchase="rowsPurchase"
                :isBusyPurchase="isBusyPurchase"
                :filterPurchaseHistory="filterPurchaseHistory"
                :pageOptions="pageOptions"
                :showingPurchase="showingPurchase"
                :showingToPurchase="showingToPurchase"
                @setPagePurchase="setPagePurchase"
                :fieldsPoint="fieldsPoint"
                :itemsPoint="itemsPoint"
                :rowsPoint="rowsPoint"
                :isBusyPoint="isBusyPoint"
                :filterPointHistory="filterPointHistory"
                :showingPoint="showingPoint"
                :showingToPoint="showingToPoint"
                @selectTab="selectTab"
                @handleChangeTake="handleChangeTake"
                :noteList="noteList"
                @updateNote="updateNote"
                @fetch="getCustomerDetail"
                :rowsCoupon="rowsCoupon"
                @getData="getData"
                :branchList="branchList"
              >
                <template v-slot:header-tab>
                  <HeaderCouponList
                    class="m-3"
                    :title="'Usage Information'"
                    :filter="formCoupon"
                    :link="'/report/customer/0'"
                    :placeholder="'Search Coupon Name, Coupon Code'"
                    :btnFilter="true"
                    :btnExport="false"
                    @sidebar="sidebarFilterCoupon"
                    @filterCoupon="searchFilterCoupon"
                    :tab="tabIndex"
                    v-if="tabIndex === 2"
                  />
                  <HeaderMenuReportPanel
                    class="m-3"
                    :title="
                      tabIndex == 4
                        ? ''
                        : tabIndex == 5
                        ? 'Booking History'
                        : 'Usage Information'
                    "
                    :filter="filterPurchaseHistory"
                    :link="'/report/customer/0'"
                    :placeholder="
                      tabIndex == 5
                        ? 'Search Booking No.'
                        : 'Search Order Number '
                    "
                    :btnFilter="true"
                    :btnExport="false"
                    :hasSearch="tabIndex == 4 ? false : true"
                    @sidebar="sidebarFilterTransaction"
                    @search="searchFilter"
                    :tab="tabIndex"
                    v-else-if="tabIndex != 0"
                  /> </template
              ></TabHistoryDetail>
            </div>
          </div>
        </div>
        <FilterReportTransaction
          ref="sidebarFilter"
          :filter="tabIndex == 1 ? filterPurchaseHistory : filterPointHistory"
          :branch="branchList"
          @filterAll="filterAll"
        />
        <FilterReportCoupon
          ref="sidebarFilterCoupons"
          :filter="formCoupon"
          @filterCoupon="filterCoupon"
          :statusFilter="statusFilter"
        />
        <!-- <ModalPoint
          ref="modalPoint"
          :id="$route.params.id"
          :loading="LoadingPoint"
          :branchList="branchList"
          :form="formUpdatePoint"
          :point="point"
          @updatePoint="updatePoint"
          :v="$v.formUpdatePoint"
          @tabIndexPoint="tabIndexPoint"
        /> -->
      </template>
      <template v-else>
        <CreateNewUser />
      </template>
    </template>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import HeaderMenuReportPanel from "@/components/report/header/HeaderMenuReportPanel";
import HeaderCouponList from "@/components/report/header/HeaderCouponList";

import TabHistoryDetail from "@/components/report/customer/detail/TabHistoryDetail";
import FilterReportTransaction from "@/components/report/customer/detail/FilterReportTransaction";
import FilterReportCoupon from "@/components/report/customer/detail/FilterReportCoupon";
import ModalPoint from "@/components/report/customer/detail/modal/ModalPoint";
import CreateNewUser from "../components/CreateNewUser";
import {
  required,
  requiredIf,
  email,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";

const Script_checkID = (id) => {
  id = String(id);

  if (id == "null") {
    return true;
  }
  if (id.trim() == "") {
    return true;
  }
  let i = 0,
    sum = 0;
  if (id.substring(0, 1) == 0) return false;
  for (i = 0, sum = 0; i < 12; i++) sum += parseFloat(id.charAt(i)) * (13 - i);
  if ((11 - (sum % 11)) % 10 != parseFloat(id.charAt(12))) return false;
  return true;
};

export default {
  components: {
    OtherLoading,
    HeaderMenuReportPanel,

    TabHistoryDetail,
    FilterReportTransaction,
    ModalPoint,
    HeaderCouponList,
    FilterReportCoupon,
    CreateNewUser,
  },
  validations: {
    formUpdatePoint: {
      branch_id: {
        required: requiredIf(function () {
          return this.typePoint === 2;
        }),
      },
      update_point: {
        required,
      },
      addPoint: {
        required: requiredIf(function () {
          return this.pointTab === 0;
        }),
      },
      deletePoint: {
        required: requiredIf(function () {
          return this.pointTab === 1;
        }),
      },
      editPoint: {
        required: requiredIf(function () {
          return this.pointTab === 2;
        }),
      },
    },
  },
  data() {
    return {
      branchCustomField: [],
      formCoupon: {
        user_guid: this.$route.params.id,
        search: "",
        start_date: null,
        end_date: null,
        status: [0],
        page: 1,
        take: 10,
      },
      isLoading: true,
      id: this.$route.params.id,
      filterPurchaseHistory: {
        user_guid: this.$route.params.id,
        search: null,
        page: 1,
        take: 10,
        branch_id: 0,
        start_date: null,
        end_date: null,
      },
      showProfile: true,
      fieldsPurchase: [
        {
          key: "invoice_no",
          label: "Order Number",
        },
        {
          key: "sale_name",
          label: "Sale Name",
        },
        {
          key: "grand_total",
          label: "Grand Total",
        },
        {
          key: "center_point",
          label: "Point (Branch Point)",
        },
        {
          key: "point",
          label: "Point",
        },
        {
          key: "payment_name",
          label: "Payment Type",
        },
        {
          key: "branch_name",
          label: "Branch",
        },
        {
          key: "sales_channel_name",
          label: "Sales Channel Name",
        },

        {
          key: "transaction_date",
          label: "Transaction Date",
        },
        {
          key: "status_name",
          label: "Status",
        },
      ],
      itemsPurchase: [],
      couponList: [],
      rowsPurchase: 0,
      isBusyPurchase: true,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      showingPurchase: 1,
      showingToPurchase: 10,
      filterPointHistory: {
        user_guid: this.$route.params.id,
        search: "",
        page: 1,
        take: 10,
        branch_id: 0,
        start_date: null,
        end_date: null,
      },
      fieldsPoint: [
        {
          key: "invoice_no",
          label: "Order Number/Code Redeem",
        },
        {
          key: "record_created_time",
          label: "Date/Time",
        },
        {
          key: "grand_total",
          label: "Spending(Baht)",
        },
        {
          key: "point",
          label: "Point",
        },
        {
          key: "current_point",
          label: "Current Point",
        },
        {
          key: "sale_name",
          label: "Sale Name",
        },
        {
          key: "branch_name",
          label: "Branch",
        },
      ],
      fieldCoupon: [
        {
          key: "name",
          label: "Coupon Name",
          tdClass: "text-left",
        },
        {
          key: "prefix",
          label: "Coupon Code",
          tdClass: "text-center",
        },
        {
          key: "invoice_no",
          label: "Invoice No.",
        },
        {
          key: "start_date",
          label: "Start Date",
        },
        {
          key: "created_time",
          label: "Date/Time Redeem",
        },
        {
          key: "used_date",
          label: "Date/Time Used",
        },
        {
          key: "status_name",
          label: "Status",
        },
        {
          key: "action",
          label: "",
        },
      ],
      itemsPoint: [],
      rowsPoint: 0,
      isBusyPoint: true,
      showingPoint: 1,
      showingToPoint: 10,
      tabIndex: 0,
      branchList: [],
      noteList: {},
      form: {
        user_guid: this.$route.params.id,
        first_name_th: null,
        last_name_th: null,
        first_name_en: null,
        last_name_en: null,
        nickname: "",
        email: "",
        telephone: "",
        member_level_id: 0,
        picture: null,
        birthday: this.birthdayCustomer,
        line_id: null,
        line_ref: null,
        home_address: null,
        branch_name: "",
        branch_no: "",
        gender: "",
        zip_code: null,
        province: null,
        town: null,
        alley: null,
        road: null,
        subdistrict: null,
        district: null,
        is_consent: 0,
        id_card: "",
        prefix: "",
        user_custom_field: [],
        user_tag: [],
        telephone_country_id: 1,
        company_branch_no: "",
        company_branch: "",
      },
      memberLevelList: [],
      FieldList: [],
      formUpdatePoint: {
        user_guid: this.$route.params.id,
        branch_id: 0,
        update_point: 0,
        update_by_user_guid: this.$route.params.id,
        update_type: 1,
        note: null,
        addPoint: null,
        deletePoint: null,
        editPoint: null,
      },
      point: {},
      typePoint: 0,
      pointTab: 0,
      LoadingPoint: false,
      customerQuestionList: [],
      formPersonalField: {
        user_guid: this.$route.params.id,
        admin_user_guid: "",
        question_list: [],
      },
      isLoadingPersonal: true,
      rowsCoupon: 0,
      isLoadingUpdatePersonal: false,
      isLoadingUpdateProfile: false,
      isConsentList: [
        { id: 1, name: "Agree" },
        { id: 2, name: "Waiting for Agree" },
        { id: 0, name: "Not Agree" },
        { id: 3, name: "Fail Consent 1" },
        { id: 4, name: "Fail Consent 2" },
        { id: 5, name: "Fail Consent 3" },
        { id: 6, name: "Withdraw" },
        { id: 7, name: "Suspend" },
      ],
      statusFilter: [
        { id: 0, name: "Cancel" },
        { id: 1, name: "Not Used" },
        { id: 2, name: "Used" },
        { id: 3, name: "Expire" },
      ],
      notFound: false,
      keepTelephone: null,
      getBranch: false,
    };
  },
  async created() {
    const result = await this.getUserGUID();
  },
  async mounted() {
    if (!this.$route.params.type) {
      await this.getData();
    }
  },
  methods: {
    async getUserGUID() {
      return new Promise(async (resolve, reject) => {
        if (this.$route.params.type) {
          const res = await this.axios(
            "/customer/tel_to_user_guid/" + this.$route.params.id.trim()
          );

          if (res.data.result == 1) {
            this.id = res.data.detail.user_guid;
            if (!res.data.detail.isCorrectTel) {
              this.$router.replace({
                path: `/report/customer/search-result/${this.$route.params.id}`,
              });
              return resolve(0);
            }
            if (res.data.detail.count == 0) {
              this.$router.replace({
                path: `/report/customer/search-result/${this.$route.params.id}`,
              });
              return resolve(0);
            }
            if (res.data.detail.count > 1) {
              this.$router.replace({
                path: `/report/customer/search-result/${this.$route.params.id}`,
              });
              return resolve(0);
            }
            this.$router.replace(
              "/report/customer/" + res.data.detail.user_guid
            );
            return resolve(0);
          } else {
            // this.notFound = true;
            this.$router.replace({
              path: `/report/customer/search-result/${this.$route.params.id}`,
            });
            return resolve(0);
            // this.isLoading = false;
          }
        }
        return resolve(1);
      });
    },
    async getData() {
      this.isLoading = true;
      await this.getCustomerDetail();
      this.isLoading = false;
    },
    selectTab(val) {
      if (val === 1) {
        this.getCustomerPurchaseHistory();
      } else if (val === 2) {
        this.getCustomerPointHistory();
      } else if (val == 3) {
        this.getCustomerCoupon(this.formCoupon);
      }
      this.filterPointHistory.page = 1;
      this.filterPointHistory.take = 10;
      this.filterPurchaseHistory.page = 1;
      this.filterPurchaseHistory.take = 10;
      this.tabIndex = val;
    },
    tabIndexPoint(val) {
      this.pointTab = val;
    },
    async getCustomerCoupon(formCoupon) {
      this.isBusyPoint = true;
      this.formCoupon = formCoupon;
      this.formCoupon.start_date = formCoupon.start_date || "";
      this.formCoupon.end_date = formCoupon.end_date || "";

      await this.$store.dispatch("getCustomerCoupon", this.formCoupon);
      const data = this.$store.state.report.stateCustomerCoupon;
      if (data.result === 1) {
        this.rowsCoupon = data.detail.total_count;
        this.couponList = data.detail.detail;
      }
      this.isBusyPoint = false;
    },
    async getPointByUser() {
      await this.$store.dispatch("getPointByReportCustomer", this.id);
      const data = this.$store.state.report.stateGetPointByReportCustomer;

      if (data.result === 1) {
        this.point = data.detail;
      }
    },
    async updatePoint(val) {
      this.LoadingPoint = true;
      await this.$store.dispatch("updatePointByReportCustomer", val);
      const data = this.$store.state.report.stateUpdatePointByReportCustomer;
      if (data.result === 1) {
        this.successAlert().then(() => {
          this.getData();
          this.$refs.modalPoint.hide(val);
        });
      } else {
        this.warningAlert(data.message);
      }
      this.LoadingPoint = false;
    },
    setGender(gender) {
      if (gender == "ไม่ระบุ") return "N/A";
      else if (gender == "ผู้ชาย") return "Male";
      else if (gender == "ผู้หญิง") return "Female";
      else return gender;
    },
    async getCustomerDetail(only = false) {
      await this.$store.dispatch("getReportCustomerDetail", this.id);
      const data = this.$store.state.report.respCustomerDetail;

      if (!data.user_guid) {
        return this.$router.replace({
          path: `/report/customer/search-result/${this.$route.params.id}`,
        });
      }

      if (data.expire_date) {
        if (this.$moment(data.expire_date).year() == 2001) {
          data.expire_date = null;
        }
      }
      data.expire_date = data.expire_date
        ? this.$moment(data.expire_date).format()
        : null;
      data.gender = this.setGender(data.gender);
      data.tier_start_date = this.$moment(data.tier_start_date).format();
      for (const field of data.user_custom_field) {
        if (field.field_type_id == 2) {
          field.user_answer.map((el) => {
            let id = field.field_choices.find((n) => n.name == el.answer);
            if (id) {
              el.sort_order = id.sort_order;
            }
          });
        }
        if (field.field_type_id == 6) this.getBranchList(field.branch_group_id);
        field.user_answer = field.user_answer.sort(
          (a, b) => a.sort_order - b.sort_order
        );
      }
      this.form = data;
      let birthDate = this.form.birthday
        ? this.form.birthday.search("00:00:00")
        : -1;

      if (birthDate === -1) {
        this.form.birthday = this.form.birthday;
      } else {
        this.form.birthday = this.$moment(this.form.birthday).format(
          "YYYY-MM-DDT00:00:00.000+07:00"
        );
      }
      if (!only) {
        this.getBranchList();
        this.getNoteCustomer();

        this.getCustomerCustomField();
      }
    },
    async getCustomerPurchaseHistory() {
      await this.$store.dispatch(
        "getReportPurchaseHistory",
        this.filterPurchaseHistory
      );
      const data = this.$store.state.report.reportPurchaseHistory;
      this.itemsPurchase = data.detail;
      this.rowsPurchase = data.total_count;
      this.isBusyPurchase = false;
    },
    setPagePurchase(filter) {
      if (this.tabIndex === 1) {
        this.filterPurchaseHistory = filter;
        this.getCustomerPurchaseHistory();
      } else if (this.tabIndex === 2) {
        this.filterPointHistory = filter;
        this.getCustomerPointHistory();
      } else {
        this.getCustomerCoupon(filter);
      }
    },
    async getCustomerPointHistory() {
      await this.$store.dispatch(
        "getReportPointHistoryList",
        this.filterPointHistory
      );
      const data = this.$store.state.report.reportPointHistoryList;
      this.itemsPoint = data.detail;
      this.rowsPoint = data.total_count;
      this.isBusyPoint = false;
    },
    sidebarFilterTransaction() {
      this.$refs.sidebarFilter.show();
    },
    sidebarFilterCoupon() {
      this.$refs.sidebarFilterCoupons.show();
    },
    async getBranchList(id) {
      if (!this.getBranch) {
        this.getBranch = true;
        const data = await this.axios(`/branch/listAll`);
        this.$nextTick(() => (this.branchList = data.data.detail));
      }
    },

    filterAll(value) {
      if (this.tabIndex === 1) {
        this.filterPurchaseHistory = value;
        this.filterPurchaseHistory.start_date = this.filterPurchaseHistory
          .start_date
          ? this.$moment(this.filterPurchaseHistory.start_date).format(
              "YYYY-MM-DD"
            )
          : null;
        this.filterPurchaseHistory.end_date = this.filterPurchaseHistory
          .end_date
          ? this.$moment(this.filterPurchaseHistory.end_date).format(
              "YYYY-MM-DD"
            )
          : null;
        this.getCustomerPurchaseHistory();
      } else if (this.tabIndex === 2) {
        this.filterPointHistory = value;
        this.filterPointHistory.start_date = this.filterPointHistory.start_date
          ? this.$moment(this.filterPointHistory.start_date).format(
              "YYYY-MM-DD"
            )
          : null;
        this.filterPointHistory.end_date = this.filterPointHistory.end_date
          ? this.$moment(this.filterPointHistory.end_date).format("YYYY-MM-DD")
          : null;
        this.getCustomerPointHistory();
      } else if (this.tabIndex === 5) {
        this.$refs.tab.$refs.booking.getList(this.filterPointHistory);
      }
    },
    filterCoupon(value) {
      this.formCoupon = value;
      this.getCustomerCoupon(this.formCoupon);
    },
    handleChangeTake(filter) {
      if (this.tabIndex === 1) {
        this.filterPurchaseHistory = filter;
        this.getCustomerPurchaseHistory();
      } else if (this.tabIndex === 2) {
        this.filterPointHistory = filter;
        this.getCustomerPointHistory();
      } else {
        this.getCustomerCoupon(filter);
      }
    },
    async getNoteCustomer() {
      await this.$store.dispatch("getNoteReportCustomer", this.id);
      const data = this.$store.state.report.respNote;
      this.noteList = data;
    },
    async updateNote(value) {
      this.$bus.$emit("showLoading");
      let body = {
        user_guid: this.id,
        note: value,
      };
      await this.$store.dispatch("updateNoteReportCustomer", body);
      const data = this.$store.state.report.updateNoteCustomer;
      if (data.result == 1) {
        this.successAlert();
        this.getNoteCustomer();
      } else {
        this.warningAlert(data.message);
      }
      this.$bus.$emit("hideLoading");
    },
    async getMemberLevelList() {
      await this.$store.dispatch("getMemberLevel");
      let data = this.$store.state.automation.memberLevel;
      if (data.result == 1) {
        this.memberLevelList = data.detail;
      }
    },

    async updateProfileDetail(form, social) {
      this.$bus.$emit("showLoading");
      form.expire_date = form.expire_date ? form.expire_date : null;
      await this.$store.dispatch("updateProfileCustomerReport", form);

      const data = this.$store.state.report.updateProfileCustomer;
      if (data.result === 1) {
        this.successAlert().then(() => this.getData());
      } else {
        this.warningAlert(data.message);
      }
      this.$bus.$emit("hideLoading");
    },
    searchFilter(value) {
      this.filterPurchaseHistory.page = 1;
      this.filterPurchaseHistory.search = value.search;
      this.getCustomerPurchaseHistory();
    },
    searchFilterCoupon(value) {
      this.formCoupon.page = 1;
      this.formCoupon.search = value.search;
      this.formCoupon.start_date = value.start_date;
      this.formCoupon.end_date = value.end_date;
      this.formCoupon.status = value.status;
      this.getCustomerCoupon(this.formCoupon);
    },
    openModalPoint(val) {
      this.typePoint = val;
      this.$refs.modalPoint.show(val);
    },
    async getCustomerCustomField() {
      this.$bus.$emit("showLoading");
      await this.$store.dispatch("getCustomerField", this.id);
      const data = this.$store.state.report.stateCustomerField;
      if (data.result === 1) {
        this.customerQuestionList = data.detail;
        this.isLoadingPersonal = false;
      }
      this.$bus.$emit("hideLoading");
    },
    async updatePersonal(value) {
      this.isLoadingUpdatePersonal = true;
      this.$bus.$emit("showLoading");
      this.formPersonalField.admin_user_guid = this.$cookies.get(
        "back_office_admin_user_guid"
      );
      this.formPersonalField.question_list = value;
      await this.$store.dispatch("updateCustomerField", this.formPersonalField);
      const data = this.$store.state.report.stateUpdateCustomerField;
      if (data.result === 1) {
        this.successAlert().then(() => {
          this.getData();
          // this.$refs.modalPoint.hide(val);e
        });
      } else {
        this.warningAlert(data.message);
      }
      this.isLoadingUpdatePersonal = false;
      this.$bus.$emit("hideLoading");
    },
    setForm(val) {
      this.form = val;
    },
    openChat(chat) {
      window.open(chat, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-right {
  right: -176px;
  overflow: hidden;
  width: 2em;
  height: 12em;
  background: #fff;
  border: none;
  box-shadow: 1px 1px 3px grey;
}
.rotate-text {
  transform: rotate(270deg);
  height: 150px;
  width: 133px;
}
.w-sidebar {
  width: 35%;
  background-color: #fff;
}
</style>
